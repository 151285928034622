@font-face {
  font-family: "NeueHaasGroteskDisp Pro Md";
  src: url("./Assets/NHaasGroteskDSPro-65Md/NHaasGroteskDSPro-65Md.eot");
  src: url("./Assets/NHaasGroteskDSPro-65Md/NHaasGroteskDSPro-65Md.eot?#iefix") format("embedded-opentype"),
    url("./Assets/NHaasGroteskDSPro-65Md/NHaasGroteskDSPro-65Md.woff2") format("woff2"),
    url("./Assets/NHaasGroteskDSPro-65Md/NHaasGroteskDSPro-65Md.woff") format("woff"),
    url("./Assets/NHaasGroteskDSPro-65Md/NHaasGroteskDSPro-65Md.ttf") format("truetype"),
    url("./Assets/NHaasGroteskDSPro-65Md/NHaasGroteskDSPro-65Md.svg#NeueHaasGroteskDisp Pro Md") format("svg");
}
@font-face {
  font-family: "NHaasGroteskTXPro-55Rg";
  src: url("./Assets/NeueHaasGroteskTextPro55Roman/NeueHaasGroteskTextPro55Roman.eot");
  src: url("./Assets/NeueHaasGroteskTextPro55Roman/NeueHaasGroteskTextPro55Roman.eot?#iefix") format("embedded-opentype"),
  url("./Assets/NeueHaasGroteskTextPro55Roman/NeueHaasGroteskTextPro55Roman.woff2") format("woff2"),
  url("./Assets/NeueHaasGroteskTextPro55Roman/NeueHaasGroteskTextPro55Roman.woff") format("woff"),
  url("./Assets/NeueHaasGroteskTextPro55Roman/NeueHaasGroteskTextPro55Roman.ttf") format("truetype"),
  url("./Assets/NeueHaasGroteskTextPro55Roman/NeueHaasGroteskTextPro55Roman.svg#NHaasGroteskTXPro-55Rg") format("svg");
}

/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url('./Assets/barlow-v12-latin/barlow-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/barlow-v12-latin/barlow-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-500 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: url('./Assets/barlow-v12-latin/barlow-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/barlow-v12-latin/barlow-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-500.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-600 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url('./Assets/barlow-v12-latin/barlow-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/barlow-v12-latin/barlow-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-600.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: url('./Assets/barlow-v12-latin/barlow-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/barlow-v12-latin/barlow-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/barlow-v12-latin/barlow-v12-latin-700.svg#Barlow') format('svg'); /* Legacy iOS */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
}
